import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PublishListComponent } from './publish-list/publish-list.component';
import { PublishDetailsComponent } from './publish-details/publish-details.component';
import { MdToHtmlPipe } from './md-to-html.pipe';
import { AboutComponent } from './menu-about/about/about.component';
import { HubComponentComponent } from './menu-hubs/hub-component/hub-component.component';
import { RefsComponentComponent } from './menu-hubs/refs-component/refs-component.component';
import { SensibilisationPesComponent } from './menu-projets/sensibilisation-pes/sensibilisation-pes.component';
import { SensibilisationPcbComponent } from './menu-projets/sensibilisation-pcb/sensibilisation-pcb.component';
import { EliminationpopcampagneComponent } from './menu-projets/eliminationpopcampagne/eliminationpopcampagne.component';
import { MineArtisanaleComponent } from './menu-projets/mine-artisanale/mine-artisanale.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { SecuritychimicComponent } from './menu-champsdaction/securitychimic/securitychimic.component';

@NgModule({
  declarations: [
    AppComponent,
    PublishListComponent,
    PublishDetailsComponent,
    MdToHtmlPipe,
    AboutComponent,
    HubComponentComponent,
    RefsComponentComponent,
    SensibilisationPesComponent,
    SensibilisationPcbComponent,
    EliminationpopcampagneComponent,
    MineArtisanaleComponent,
    SecuritychimicComponent,
  ],
  imports: [
    BrowserModule,
    NgxPaginationModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
