import { Component, OnInit } from '@angular/core';
import { ContentfulService } from '../../contentful.service';
import { Entry } from 'contentful';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

aboutContent: Entry<any>;

  constructor(private contentfulService: ContentfulService) { }

  ngOnInit() {
    this.contentfulService.getAbout()
    .then(x => this.aboutContent = x);
  }

  downloadDocument(urlDocument) {
    window.location.href = urlDocument;
  }

}
