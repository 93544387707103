import { AfterViewInit, Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { ContentfulService } from './contentful.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit  {
  title = 'crepdApp';
  @ViewChild('colId') colId: ElementRef;

  constructor(
    private ContentfulService: ContentfulService
  ) {}

  // ngAfterViewInit() {
  //   this.ContentfulService.onTitleChange(title => this.title = title);
  //     //console.log(this.colId.nativeElement.innerHTML);
  // }

  ngOnInit() {
    this.ContentfulService.onTitleChange(title => this.title = title)
  }
}
