import { Injectable } from '@angular/core';
import { createClient, Entry, Space, ContentfulClientApi } from 'contentful';
import { environment } from '../environments/environment';

// change these to include your own settings
const DEFAULT_CONFIG = {
  credentials: {
    space: 'ny5rwcs78eu6',
    accessToken: 'b6241b705343db3a9cb7b8e0ed7bf48d79060e9c25a11ce0ff3652dcd4775001',
  },

  contentTypeIds: {
    publish: 'course',
    about: 'about'
  }
}

@Injectable({
  providedIn: 'root'
})
export class ContentfulService {
  // private cdaClient = createClient({
  //   space: environment.contentful.spaceId,
  //   accessToken: environment.contentful.token
  // });
  cdaClient: ContentfulClientApi;
  config: {
    space: string,
    accessToken: string
  };
  titleHandlers: Function[]

  constructor() {
    try {
      this.config = JSON.parse(localStorage.catalogConfig);
    } catch (e) {
      this.config = DEFAULT_CONFIG.credentials;
    }

    this.titleHandlers = [];
    this._createClient();
    this.getSpace();
  }

  onTitleChange(fn): void {
    this.titleHandlers.push(fn)
  }

  // get the current space
  getSpace(): Promise<Space> {
    return this.cdaClient.getSpace()
      .then(space => {
        this.titleHandlers.forEach(handler => handler(space.name))

        return space;
      })
  }

  // fetch products
  getContent1(query?: object): Promise<Entry<any>[]> {
    return this.cdaClient.getEntries(Object.assign({
      content_type: DEFAULT_CONFIG.contentTypeIds.publish
    }, query))
    .then(res => res.items);
  }

  // fetch products with a given slug
  // and return one of them
  getContentDetails1(publishId: string): Promise<Entry<any>> {
    return this.getContent1({ 'sys.id': publishId })
    .then(items => items[0])
  }

  // fetch categories
  getCategories(): Promise<Entry<any>[]> {
    return this.cdaClient.getEntries({
      content_type: DEFAULT_CONFIG.contentTypeIds.publish
    })
    .then(res => res.items);
  }

  // return a custom config if available
  getConfig(): { space: string, accessToken: string } {
    return this.config !== DEFAULT_CONFIG.credentials ?
      Object.assign({}, this.config) :
      { space: '', accessToken: '' };
  }

  // set a new config and store it in localStorage
  setConfig(config: {space: string, accessToken: string}) {
    localStorage.setItem('catalogConfig', JSON.stringify(config));
    this.config = config;

    this._createClient();
    this.getSpace();

    return Object.assign({}, this.config);
  }

  // set config back to default values
  resetConfig() {
    localStorage.removeItem('catalogConfig');
    this.config = DEFAULT_CONFIG.credentials;

    this._createClient();
    this.getSpace();

    return Object.assign({}, this.config);
  }

  _createClient() {
    this.cdaClient = createClient({
      space: this.config.space,
      accessToken: this.config.accessToken
    });
  }
//////////////////////////////////////
  getContent(query?: object): Promise<Entry<any>[]> {
    return this.cdaClient.getEntries(Object.assign({
      content_type: 'course'
    }, query))
    .then(res => res.items);
  }
  getAbout(query?: object): Promise<Entry<any>> {
    return this.cdaClient.getEntries(Object.assign({
      content_type: 'about'
    }, query))
    .then(res => res.items[0]);
  }
  getContentDetails(publishId): Promise<Entry<any>> {
    return this.cdaClient.getEntries(Object.assign({
      content_type: 'course'
    }, {'sys.id': publishId}))
    .then(res => res.items[0]);
  }
   
  getContentMenu(publishId): Promise<Entry<any>> {
    return this.cdaClient.getEntries(Object.assign({
      content_type: 'course'
    }, {'sys.id': publishId}))
    .then(res => res.items[0]);
  }

}
