import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mine-artisanale',
  templateUrl: './mine-artisanale.component.html',
  styleUrls: ['./mine-artisanale.component.css']
})
export class MineArtisanaleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
