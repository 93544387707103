import { switchMap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ContentfulService } from '../contentful.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Entry } from 'contentful';

@Component({
  selector: 'app-publish-details',
  templateUrl: './publish-details.component.html',
  styleUrls: ['./publish-details.component.css']
})
export class PublishDetailsComponent implements OnInit {

  publish: Entry<any>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ContentfulService: ContentfulService
  ) { }

  ngOnInit() {
    // this.route.paramMap
    // .pipe(switchMap((params: ParamMap) => this.ContentfulService.getContentDetails1(params.get('title'))))
    // .subscribe(publish => this.publish = publish);

    const publishId = this.route.snapshot.paramMap.get('id');
    this.ContentfulService.getContentDetails1(publishId)
      .then((publish) => {
        this.publish = publish;       
        if (this.publish.fields['documentID'].length >= 1) {

          this.publish.fields['boolDoc'] = true;
        }
        else {
          this.publish.fields['boolDoc'] = false;
        }

        if (this.publish.fields['videoID'] != null) {
          this.publish.fields['boolVideo'] = true;
        }
        else {
          this.publish.fields['boolVideo'] = false;
        }
      });
      console.log("////////////////////////////////////////");
console.log( this.publish.fields['boolDoc']);
  }
  goToList() {
    this.router.navigate(['']);
  }

  downloadDocument(urlDocument) {
    window.location.href = urlDocument;
  }

  trimFileName (urlDocument) : String {
      let trimUrl = urlDocument.substring(0, 30) + "...";
      return trimUrl;
  }
}
