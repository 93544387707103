import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap  } from '@angular/router';
import { ContentfulService } from '../contentful.service';
import { Entry } from 'contentful';
import { stringify } from '@angular/core/src/util';
import { Observable  } from 'rxjs';

@Component({
  selector: 'app-publish-list',
  templateUrl: './publish-list.component.html',
  styleUrls: ['./publish-list.component.css']
})
export class PublishListComponent implements OnInit {
  publishContent: Entry<any>[] = [];
  config: any;

 
  constructor(private route:  ActivatedRoute, private router: Router, private contentfulService: ContentfulService) {
   this.config = {
      itemsPerPage: 5,
      currentPage: 1,
      totalItems: this.publishContent.entries.length
    };
  
   }
   pageChanged(event){
    this.config.currentPage = event;
  }
  ngOnInit() {
    this.contentfulService.getContent1()
      .then(x => this.publishContent = x)
      .then(x => this.replaceContent(x))
      .then(x => this.sortContent());     
  }

  replaceContent(publishContent) {
    for (let i = 0; i < this.publishContent.length; i++) {
      const cStr: string = publishContent[i].fields['longDescription'];
      const splitted = cStr.split(' ', 30); // cStr = cStr.substring(0, 150);
      let resum = '';
      splitted.forEach(function (value) {
        resum += value + ' ';
      });
      resum += '...';
      publishContent[i].fields['longDescription'] = resum;

    }
    
  }

  sortContent(){
    this.publishContent.sort((t1,t2) => {
        const tempDate1 = t1.fields['publicationDate']
        const tempDate2 = t2.fields['publicationDate']
        if (tempDate1 < tempDate2) { return 1; }
        if (tempDate1 > tempDate2) { return -1; }
        return 0;
    });
   }
  
  nextPagePublishContent(){ 
    this.router.navigate(['/about']);
   }

  goToPublishDetailsPage(id) {
    this.router.navigate(['/publish/details', id]);
  }

  gotoPagePublishContent(iPage){
    this.router.navigate(['/publish', iPage]);
  }

  // downloadDocument(urlDocument) {
  //     window.location.href = urlDocument;
  // }
}
